// React
import React from "react"

// Gatsby
import {
    Link
} from "gatsby"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Styles
import "../styles/pages/NotFoundPage.scss"

// 404 page component
const NotFoundPage = ({
    location
}) => {

    // Page data
    const pageTitle = "Not found";
    const seoTitle = "404: Not Found";

    // Render
    return (
        <Layout>

            <SEO
                title={seoTitle}
                description={pageTitle}
                pathname={location.pathname}
            />
                
            {/* The 404 not found page */}
            <div id="notfound-page" >

                <main>

                    <h1>
                        {pageTitle} :(
                    </h1>

                    <p>
                        Remember that time when Mario fought all the way through that castle - only to find that his princess wasn't even there?
                    </p>

                    <p>
                        Yeah, it's that kind of a situation.
                    </p>

                    <p>
                        <Link
                            to={'/'} 
                            className="button button-secondary"
                        >
                            Warp Whistle ?
                        </Link>
                    </p>

                </main>

                <section>
                    
                </section>   

            </div>             

        </Layout>
    )
}

// Export component
export default NotFoundPage